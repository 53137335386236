<template>
  <div>
    <section class="hero-section-1 sec1-ptb-first">
      <div class="container">
        <div class="row justify-content-between">
          <div class="col-md-6 col-lg-6">
            <div class="hero-content-left ptb-90 text-white clearfix" style="display:flex;flex-direction:column;justify-content:flex-start;align-items:flex-start">
              <h1 class="text-white fw800 sec-pt50-first">{{ $t('page.index.title') }}</h1>
              <h3 class="text-white sec-pt50-first" style="margin-left:auto">———— {{ $t('page.index.subtitle') }}</h3>
              <div class="signup" style="margin-top:80px">
                <router-link to="/contact" :title="$t('page.index.contactUs')" class="cus_signup" style="margin:0">
                  {{ $t('page.index.contactUs') }}
                </router-link>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-6">
            <div class="hero-content-right ptb-100 text-white">
              <section class="pc-contact">
                <div class="video-wrapper">
                  <video width="250" height="500" aspect-ratio="auto 276/538" preload="none" autoplay="autoplay" muted="muted" controls="controls">
                    <source src="/videos/8b03ae65-61dd-4509-b095-abce32e01e8d.mp4" type="video/mp4">
                    <source src="/videos/8b03ae65-61dd-4509-b095-abce32e01e8d.mp4" type="video/ogg">
                    <source src="/videos/8b03ae65-61dd-4509-b095-abce32e01e8d.mp4" type="video/webm">
                  </video>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--
    <section class="sec1-padding">
      <div class="custom-container">
        <PaymentMethodView></PaymentMethodView>
      </div>
    </section>
    -->
    <section class="pp-sec-pad wrapper-gray">
      <div class="sec2-sa-content pt-0 ft-center">
        <h2 class="mt-0">{{ $t('page.index.global.payment.title1') }}</h2>
        <h2 class="mt-0">{{ $t('page.index.global.payment.title2') }}</h2>
        <img src="/images/usd.png">
        <p></p>
        <div class="max-width-800 inline-block">
          {{ $t('page.index.global.payment.desc') }}
        </div>
        <p></p>
        <h4>{{ $t('page.index.global.payment.languages') }}</h4>
        <h4>{{ $t('page.index.global.payment.methods') }}</h4>
      </div>
    </section>
    <section class="sec1-padding pb-0">
      <div class="custom-container">
        <div class="sec2-sa"><h2 class="bottom-buffer0 text-center">
          {{ $t('page.index.global.api.title') }}
        </h2>
          <h2 class="s top-buffer0 text-center">
            {{ $t('page.index.global.api.subtitle') }}
          </h2>
          <div class="row">
            <div class="col-sm-5 col-md-7">
              <div class="ft-center">
                <img src="/images/icon-gp-sell-in-over-countries-300x300.png">
              </div>
            </div>
            <div class="col-sm-7 col-md-5" style="margin:40px 0">
              <h3 class="top-buffer0" style="margin-bottom:20px">
                {{ $t('page.index.global.api.advantage.title') }}
              </h3>
              <p style="line-height:26px;color:#666;font-size:16px;margin:10px auto">
                {{ $t('page.index.global.api.advantage.desc') }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="sec1 sec1-padding pb-0">
      <div class="container">
        <div class="row">
          <div class="sec1-title">
            <h2>{{ $t('page.index.gateway') }}</h2>
          </div>
        </div>
        <div class="row">
          <div xs="12" class="col-sm-6 col-md-3">
            <div class="sec1-f-main">
              <div class="sec1-f-img">
                <img src="/images/icon1.svg" :alt="$t('page.index.gateway.integration.title')" draggable="false" class="img-responsive">
              </div>
              <div class="sec1-f-content" style="height:170px;border:0 dashed red">
                <h3>{{ $t('page.index.gateway.integration.title') }}</h3>
                <p class="sec1-p-extra">{{ $t('page.index.gateway.integration.desc') }}</p>
              </div>
            </div>
          </div>
          <div xs="12" class="col-sm-6 col-md-3">
            <div class="sec1-f-main">
              <div class="sec1-f-img">
                <img src="/images/icon2.svg" :alt="$t('page.index.gateway.success.rate.title')" draggable="false" class="img-responsive">
              </div>
              <div class="sec1-f-content" style="height:170px;border:0 dashed red">
                <h3>{{ $t('page.index.gateway.success.rate.title') }}</h3>
                <p class="sec1-p-extra">{{ $t('page.index.gateway.success.rate.desc') }}</p>
              </div>
            </div>
          </div>
          <div xs="12" class="col-sm-6 col-md-3">
            <div class="sec1-f-main">
              <div class="sec1-f-img">
                <img src="/images/icon3.svg" :alt="$t('page.index.gateway.payment.method.title')" draggable="false" class="img-responsive">
              </div>
              <div class="sec1-f-content" style="height:170px;border:0 dashed red">
                <h3>{{ $t('page.index.gateway.payment.method.title') }}</h3>
                <p>{{ $t('page.index.gateway.payment.method.desc') }}</p>
              </div>
            </div>
          </div>
          <div xs="12" class="col-sm-6 col-md-3">
            <div class="sec1-f-main">
              <div class="sec1-f-img">
                <img src="/images/icon4.svg" :alt="$t('page.index.gateway.support.title')" draggable="false" class="img-responsive">
              </div>
              <div class="sec1-f-content" style="height:170px;border:0 dashed red">
                <h3>{{ $t('page.index.gateway.support.title') }}</h3>
                <p>{{ $t('page.index.gateway.support.desc') }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="sec1-padding">
      <div class="custom-container">
        <div class="sec2-sa">
          <div class="ld-pm-bg sec2-sa-main sec2-sa-elips">
            <div class="row">
              <div class="col-md-5">
                <div class="sec2-sa-img">
                  <img src="/images/120-payment-methods.svg" draggable="false" alt="Payment methods" class="ld-cus-g-width img-responsive hidden-sm">
                </div>
              </div>
              <div class="col-md-7">
                <div class="sec2-sa-content pt-0">
                  <h2>{{ $t('page.index.customer.payment.title') }}<br>
                  </h2>
                  <p class="max-width-550">{{ $t('page.index.customer.payment.desc') }}</p>
                </div>
                <div class="sec2-sa-footer-img sec2-sa-footer-img-margin">
                  <img src="/images/payment-options.svg" alt="Payment methods" draggable="false" class="img-responsive">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="sec1-padding">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <div class="sec1-ctm-style">
              <img src="/images/pci-dss-graphic.svg" alt="PCI DSS Security" draggable="false" class="img-responsive">
            </div>
          </div>
          <div class="col-md-5 offset-md-1">
            <div class="sec1-ctm-details">
              <h2>{{ $t('page.index.customer.fund.safety.title') }}</h2>
              <h4>{{ $t('page.index.customer.fund.safety.pcidss.title') }}</h4>
              <p>{{ $t('page.index.customer.fund.safety.pcidss.desc') }}</p>
              <h4>{{ $t('page.index.customer.fund.safety.ssl.title') }}</h4>
              <p>{{ $t('page.index.customer.fund.safety.ssl.desc') }}</p>
              <h4>{{ $t('page.index.customer.fund.safety.encryption.title') }}</h4>
              <p>{{ $t('page.index.customer.fund.safety.encryption.desc') }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <CustomerView></CustomerView>
  </div>
</template>

<script>
import CustomerView from "@/components/CustomerView";
//import PaymentMethodView from "@/components/PaymentMethodView";
export default {
  name: "IndexView",
  components: {
    //PaymentMethodView,
    CustomerView
  },
  setup() {

    return {
    };
  },
}
</script>

<style scoped>

</style>